import { GET_STP_SUMMARY_DATA } from 'toolkit/constants/ActionTypes';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  summaryData: null,
};

const stockTargetPriceReducer = createReducer(initialState, (builder) => {
  builder.addCase(GET_STP_SUMMARY_DATA, (state, action) => {
    state.summaryData = action.payload;
  });
});

export default stockTargetPriceReducer;
