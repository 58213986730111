import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  TOGGLE_APP_DRAWER,
  UPDATING_CONTENT,
  SET_USER,
  COMMON_SET_SEARCH_STOCK,
  COMMON_SET_SELECTED_STOCK,
  COMMON_STOCK_VALIDITY_CHECK,
} from 'toolkit/constants/ActionTypes';
import { createReducer } from '@reduxjs/toolkit';

const INIT_STATE = {
  error: '',
  loading: false,
  isAppDrawerOpen: false,
  updatingContent: false,
  message: '',
  userInfo: null,
  searchItemList: [],
  selectedStock: '',
  isValid: 'ADANAA',
  stockInformations: null,
};

const commonReducer = createReducer(INIT_STATE, (builder) => {
  builder
    .addCase(FETCH_START, (state) => {
      state.error = '';
      state.message = '';
      state.loading = true;
    })
    .addCase(UPDATING_CONTENT, (state) => {
      state.error = '';
      state.message = '';
      state.updatingContent = true;
    })
    .addCase(FETCH_SUCCESS, (state) => {
      state.error = '';
      state.message = '';
      state.loading = false;
      state.updatingContent = false;
    })
    .addCase(SHOW_MESSAGE, (state, action) => {
      state.error = '';
      state.message = action.payload;
      state.loading = false;
      state.updatingContent = false;
    })
    .addCase(FETCH_ERROR, (state, action) => {
      state.error =
        action.payload?.response?.data?.message || action.payload.message;
      state.message =
        action.payload?.response?.data?.message || action.payload.message;
      state.loading = false;
      state.updatingContent = false;
    })
    .addCase(HIDE_MESSAGE, (state) => {
      state.error = '';
      state.message = '';
      state.loading = false;
      state.updatingContent = false;
    })
    .addCase(TOGGLE_APP_DRAWER, (state) => {
      state.isAppDrawerOpen = !state.isAppDrawerOpen;
    })
    .addCase(SET_USER, (state, action) => {
      state.userInfo = action.payload;
    })
    .addCase(COMMON_SET_SEARCH_STOCK, (state, action) => {
      state.searchItemList = action.payload;
    })
    .addCase(COMMON_SET_SELECTED_STOCK, (state, action) => {
      state.selectedStock = action.payload;
    })
    .addCase(COMMON_STOCK_VALIDITY_CHECK, (state, action) => {
      console.log('state', state);
      state.isValid = action.payload.isValid;
      state.stockInformations = action.payload.stockInformations;
      state.selectedStock = action.payload.stockInformations?.stock_code || '';
    });
});

export default commonReducer;
