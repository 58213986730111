import React from 'react';
import { RoutePermittedRole } from '@veo/constants/AppEnums';

const TradingViewWidget = React.lazy(() => import('./TradingViewWidget'));
const TradingViewHeatMap = React.lazy(() => import('./TradingViewHeatMap'));
const TradindViewScreener = React.lazy(() => import('./TradindViewScreener'));
const Page2 = React.lazy(() => import('./Page2'));

export const samplePagesConfigs = [
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/TradingViewWidget',
    element: <TradingViewWidget />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/TradingViewHeatMap',
    element: <TradingViewHeatMap />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/TradindViewScreener',
    element: <TradindViewScreener />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/sample/page-2',
    element: <Page2 />,
  },
];
