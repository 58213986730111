import {
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  GET_STP_SUMMARY_DATA,
} from 'toolkit/constants/ActionTypes';

import jwtAxios from '@veo/services/auth/jwt-auth';

import { appIntl } from '@veo/helpers/Common';
const saUrl = '/stock-target-price';

export const onGetSTPGetSummaryData = (stockCode) => {
  const { messages } = appIntl();

  return (dispatch, getState) => {
    dispatch({ type: FETCH_START });
    const state = getState(); // Mevcut state'i alın
    const emValue = state.stockAnlysis.em; // State'den gerekli 'em' değerini alın. 'someReducer' kısmını state'inizin yapısına göre değiştirin.

    let params = { stockCode, em: emValue };

    jwtAxios
      .get(`${saUrl}/get-summary-data`, { params })

      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_STP_SUMMARY_DATA,
            payload: data.data.result,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error?.message || 'Hata' });
      });
  };
};
