import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, Card, CardBody } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from '@veo/hooks/useDebounce';
import {
  onGetStockCommonStokList,
  onSetCommonCurrentStock,
} from 'toolkit/actions';
import ItemsList from './ItemsList';

const AppStockViewModal = ({ isOpen, toggle, onStockSelect }) => {
  const [query, setQuery] = useState('');
  const dispatch = useDispatch();
  const { stockList } = useSelector(({ stockCommon }) => stockCommon);
  const debouncedQuery = useDebounce(query, 300);

  useEffect(() => {
    if (debouncedQuery) {
      dispatch(onGetStockCommonStokList(debouncedQuery));
    }
  }, [debouncedQuery, dispatch]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };
  const handleStockClick = (stockCode) => {
    onSetCommonCurrentStock(stockCode);
    onStockSelect(stockCode); // AppStockView'den gelen hisse seçim fonksiyonunu çağır
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size='lg'
      backdrop='static' // Modalın dışına tıklanınca kapanmasını engelleyin
      keyboard={false}
    >
      <ModalHeader toggle={toggle}>Hisse Seçimi</ModalHeader>
      <ModalBody>
        <Card>
          <CardBody className='border border-dashed border-start-0'>
            <input
              type='text'
              className='fuzzy-search form-control mb-2'
              placeholder='Hisse Ara'
              value={query}
              onChange={handleInputChange}
            />
            <div id='fuzzysearch-list'>
              <SimpleBar style={{ height: '382px' }}>
                <ItemsList items={stockList} onStockClick={handleStockClick} />
              </SimpleBar>
            </div>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  );
};
AppStockViewModal.propTypes = {
  isOpen: PropTypes.bool.isRequired, // isOpen prop'u boolean ve zorunlu
  toggle: PropTypes.func.isRequired,
  onStockSelect: PropTypes.func.isRequired, // toggle prop'u fonksiyon ve zorunlu
};
export default AppStockViewModal;
