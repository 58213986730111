import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, Card, CardBody } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from '@veo/hooks/useDebounce';
import { useNavigate } from 'react-router-dom';
import {
  onGetStockCommonStokList,
  onSetCommonCurrentStock,
} from 'toolkit/actions';
import ItemsList from './ItemsList';

const AppStockSearchModal = ({ isOpen, toggle }) => {
  const [query, setQuery] = useState('');
  const dispatch = useDispatch();
  const { stockList } = useSelector(({ stockCommon }) => stockCommon);
  const debouncedQuery = useDebounce(query, 300);
  const navigate = useNavigate();
  const inputRef = useRef(null);

  useEffect(() => {
    if (debouncedQuery) {
      dispatch(onGetStockCommonStokList(debouncedQuery));
    }
  }, [debouncedQuery, dispatch]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleStockClick = (stockCode) => {
    dispatch(onSetCommonCurrentStock(stockCode));
    setQuery('');
    navigate(`/hisse/analiz/${stockCode}`);
    toggle();
  };

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  useEffect(() => {
    if (isOpen) {
      // Bir miktar gecikme ekleyerek focus işlemini deneyin
      const timer = setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 100); // 100ms gibi küçük bir gecikme

      return () => clearTimeout(timer);
    }
  }, [isOpen]);
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size='lg'
      backdrop='static'
      keyboard={false}
      onOpened={focusInput} // Modal tamamen açıldığında input'a focus ol
    >
      <ModalHeader toggle={toggle}>Analiz için hisse seçiniz...</ModalHeader>
      <ModalBody>
        <Card>
          <CardBody className='border border-dashed border-start-0'>
            <form className='app-search d-none d-md-block'>
              <div className='position-relative'>
                <input
                  className='form-control'
                  placeholder='Hisse ara...'
                  id='search-options'
                  type='search'
                  ref={inputRef}
                  value={query}
                  onChange={handleInputChange}
                  autoComplete='off'
                />
                <span className='mdi mdi-magnify search-widget-icon'></span>
                <span
                  className='mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none'
                  id='search-close-options'
                ></span>
              </div>
            </form>
            <div id='fuzzysearch-list'>
              <SimpleBar style={{ height: '382px' }}>
                <ItemsList items={stockList} onStockClick={handleStockClick} />
              </SimpleBar>
            </div>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  );
};

AppStockSearchModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default AppStockSearchModal;
