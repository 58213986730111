/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  FETCH_ERROR,
  FETCH_START,
  SHOW_MESSAGE,
  SCH_GET_SCHEDULER_LIST,
  SCH_GET_SCHEDULER_LOG_LIST,
  SCH_GET_SCHEDULER_METHOD_LIST,
  SCH_GET_SCHEDULER_STATUS_LIST,
  SCH_GET_SCHEDULER_TASK,
  SCH_CREATE_SCHEDULER_TASK,
  SCH_UPDATE_SCHEDULER_TASK,
  SCH_UPDATING_CONTENT,
  SCH_FETCH_SUCCESS,
  SCH_DELETE_SCHEDULER_TASK,
  SCH_FETCH_START,
  SCH_FETCH_ERROR,
  SCH_RUN_SCHEDULER_TASK,
  SCH_STOP_SCHEDULER_TASK,
  FETCH_SUCCESS,
} from 'toolkit/constants/ActionTypes';
import jwtAxios from '@veo/services/auth/jwt-auth';

import { appIntl } from '@veo/helpers/Common';

export const schGetSchedulerList = () => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });

    jwtAxios
      .get('/scheduler', {})
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: SCH_GET_SCHEDULER_LIST, payload: data.data.result });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error?.message || 'Hata' });
        toast.error(error?.message || 'Hata', {
          autoClose: 3000,
        });
      });
  };
};
export const schGetSchedulerLogList = (params) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: SCH_FETCH_START });
    dispatch({
      type: SCH_GET_SCHEDULER_LOG_LIST,
      payload: null,
    });
    jwtAxios
      .get('/scheduler-log', { params })
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: SCH_FETCH_SUCCESS });
          dispatch({
            type: SCH_GET_SCHEDULER_LOG_LIST,
            payload: data.data.result,
          });
        } else {
          dispatch({
            type: SCH_FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error?.message || 'Hata' });
        toast.error(error?.message || 'Hata', {
          autoClose: 3000,
        });
      });
  };
};
export const schGetSchedulerMethodList = () => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .get('/scheduler/get-methods')
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: SCH_GET_SCHEDULER_METHOD_LIST,
            payload: data.data.result,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};
export const schGetSchedulerStatusList = () => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .get('/scheduler/get-status')
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: SCH_GET_SCHEDULER_STATUS_LIST,
            payload: data.data.result,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};
export const schGetSchedulerTask = (id) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: SCH_UPDATING_CONTENT });
    if (!id) {
      dispatch({
        type: SCH_GET_SCHEDULER_TASK,
        payload: null,
      });
      dispatch({ type: SCH_FETCH_SUCCESS });
      return;
    }

    jwtAxios
      .get(`/scheduler/${id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: SCH_FETCH_SUCCESS });
          dispatch({
            type: SCH_GET_SCHEDULER_TASK,
            payload: data.data.result,
          });
        } else {
          dispatch({
            type: SCH_FETCH_ERROR,
            payload: messages('message.somethingWentWrong'),
          });
        }
      })
      .catch((error) => {
        dispatch({ type: SCH_FETCH_ERROR, payload: error.message });
      });
  };
};

export const schCreateSchedulerTask = (task) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch({ type: SCH_UPDATING_CONTENT });

    jwtAxios
      .post(`/scheduler`, task)
      .then((data) => {
        if (data.status === 201) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: SCH_CREATE_SCHEDULER_TASK,
            payload: data.data.result,
          });
          //   toast.success('Yeni Görev Başarı ile Oluşturuldu.', {
          //     autoClose: 3000,
          //   });
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages['message.TaskCreated'],
          });
          // dispatch(push('/delete-success-uri'));
          //   dispatch({
          //     type: SHOW_MESSAGE,
          //     payload: messages['message.contactCreated'],
          //   });
          // } else {
          //   dispatch({
          //     type: FETCH_ERROR,
          //     payload: messages['message.somethingWentWrong'],
          //   });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error });
        // toast.error(error?.message || 'Hata', {
        //   autoClose: 3000,
        // });
        // dispatch({ type: SCH_FETCH_SUCCESS, payload: error.message });
      });
  };
};
export const schUpdateSchedulerTask = (task) => {
  // eslint-disable-next-line no-unused-vars
  const { messages } = appIntl();
  const id = task['id'];
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch({ type: SCH_UPDATING_CONTENT });
    jwtAxios
      .patch(`/scheduler/${id}`, task)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: SCH_UPDATE_SCHEDULER_TASK,
            payload: data.data.result,
          });
          dispatch({ type: SCH_FETCH_SUCCESS });
          toast.success('Görev Başarı ile Kaydedildi.', {
            autoClose: 3000,
          });
          //   dispatch({
          //     type: SHOW_MESSAGE,
          //     payload: messages['message.contactCreated'],
          //   });
          // } else {
          //   dispatch({
          //     type: FETCH_ERROR,
          //     payload: messages['message.somethingWentWrong'],
          //   });
        }
      })
      .catch((error) => {
        // dispatch({type: SCH_FETCH_SUCCESS});
        toast.error(error?.message || 'Hata', {
          autoClose: 3000,
        });
        dispatch({ type: SCH_FETCH_ERROR, payload: error.message });
      });
  };
};
export const schDeleteSchedulerTask = (task) => {
  const id = task['id'];
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .delete(`/scheduler/${id}`)
      .then((data) => {
        if (data.status === 200) {
          toast.success('Todo Added Successfully', { autoClose: 3000 });

          dispatch({
            type: SCH_DELETE_SCHEDULER_TASK,
            payload: data.data.result,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const schRunSchedulerTask = (id) => {
  //   const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });

    jwtAxios
      .post(`/scheduler/run-task/${id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          toast.success('Görev Çalışmaya başladı', {
            autoClose: 3000,
          });
          dispatch({
            type: SCH_RUN_SCHEDULER_TASK,
            payload: data.data.result,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.message || 'Hata', {
          autoClose: 3000,
        });
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const schStopSchedulerTask = (id) => {
  //   const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });

    jwtAxios
      .post(`/scheduler/stop-task/${id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          toast.success('Görev Çalışmaya başladı', {
            autoClose: 3000,
          });
          dispatch({
            type: SCH_STOP_SCHEDULER_TASK,
            payload: data.data.result,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.message || 'Hata', {
          autoClose: 3000,
        });
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};
