import React from 'react';
import { useSelector } from 'react-redux';
import TradingViewPerformance from './TradingViewPerformance';
import './index.scss';
import AppMetaTags from '@veo/components/AppMetaTags';

const AppCompanyHeader = () => {
  const { stockInformations } = useSelector(({ stockCommon }) => stockCommon);
  const stockCode = stockInformations?.stock_code;

  const stockName = stockInformations?.stock_name;
  const title = stockCode + ' - ' + stockName;

  return (
    <>
      <AppMetaTags title={title} />
      <div className='row hta-company-header'>
        <div className='col-12 '>
          <div className='page-title-box4 p-2 '>
            <div className='d-flex align-items-center'>
              <div className='avatar-sm me-3'>
                <div className='avatar-title rounded  text-white text-center font-weight-bold'>
                  {stockCode}
                </div>
              </div>

              <div className='flex-grow-1'>
                <h4 className='mb-1'>{stockCode}</h4>

                <div> {stockName}</div>
              </div>

              <TradingViewPerformance
                stockCode={stockCode}
              ></TradingViewPerformance>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppCompanyHeader;
