import {
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  GET_SA_DASHBORAD_DATA,
  SET_SA_NUMBER_OF_QUARTER,
  SET_SA_HIDE_TOP_BOX,
  SET_SA_SHOW_AVG,
  SET_SA_EM,
  GET_SA_PRICE_LIST,
  GET_SA_STOCK_COMPARISON_DATA,
  GET_SA_MODAL_CHART_DATA,
} from 'toolkit/constants/ActionTypes';

import jwtAxios from '@veo/services/auth/jwt-auth';

import { appIntl } from '@veo/helpers/Common';
const saUrl = '/stock-analysis';
export const onGetSADashboradData = (stockCode) => {
  const { messages } = appIntl();

  return (dispatch, getState) => {
    dispatch({ type: FETCH_START });
    const state = getState(); // Mevcut state'i alın
    const emValue = state.stockAnlysis.em; // State'den gerekli 'em' değerini alın. 'someReducer' kısmını state'inizin yapısına göre değiştirin.

    let params = { stockCode, em: emValue };

    jwtAxios
      .get(`${saUrl}/get-dashboard-data`, { params })

      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_SA_DASHBORAD_DATA,
            payload: data.data.result,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error?.message || 'Hata' });
      });
  };
};

export const onSetSANumberOfQuarter = (check) => {
  return (dispatch) =>
    dispatch({ type: SET_SA_NUMBER_OF_QUARTER, payload: check });
};
export const onSetSAHideTopBox = () => {
  return (dispatch) => dispatch({ type: SET_SA_HIDE_TOP_BOX });
};
export const onSetSAShowAvg = () => {
  return (dispatch) => dispatch({ type: SET_SA_SHOW_AVG });
};
export const onSetSASEm = () => {
  return (dispatch) => dispatch({ type: SET_SA_EM });
};

export const onGetSAPriceList = (stockCode) => {
  const { messages } = appIntl();

  return (dispatch, getState) => {
    dispatch({ type: FETCH_START });
    const state = getState(); // Mevcut state'i alın
    const emValue = state.stockAnlysis.em; // State'den gerekli 'em' değerini alın. 'someReducer' kısmını state'inizin yapısına göre değiştirin.

    let params = { stockCode, em: emValue };

    jwtAxios
      .get(`${saUrl}/get-price-data`, { params })

      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_SA_PRICE_LIST,
            payload: data.data.result,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error?.message || 'Hata' });
      });
  };
};

export const onGetSAStockComparisonData = (params) => {
  const { messages } = appIntl();

  return (dispatch, getState) => {
    dispatch({ type: FETCH_START });
    const state = getState(); // Mevcut state'i alın
    const emValue = state.stockAnlysis.em; // State'den gerekli 'em' değerini alın. 'someReducer' kısmını state'inizin yapısına göre değiştirin.

    params = { ...params, em: emValue };
    let reportName = params.reportName;
    jwtAxios
      .get(`${saUrl}/get-report/${reportName}`, { params })

      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_SA_STOCK_COMPARISON_DATA,
            payload: data.data.result,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error?.message || 'Hata' });
      });
  };
};

export const onGetSAModalChartData = (params) => {
  const { messages } = appIntl();

  return (dispatch, getState) => {
    dispatch({ type: FETCH_START });
    const state = getState(); // Mevcut state'i alın
    const emValue = state.stockAnlysis.em; // State'den gerekli 'em' değerini alın. 'someReducer' kısmını state'inizin yapısına göre değiştirin.

    params = { ...params, em: emValue };
    dispatch({
      type: GET_SA_MODAL_CHART_DATA,
      payload: [],
    });
    jwtAxios
      .get(`${saUrl}/get-modal-chart-data`, { params })

      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_SA_MODAL_CHART_DATA,
            payload: data.data.result,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error?.message || 'Hata' });
      });
  };
};

export const resetSAModalChartData = () => {
  return (dispatch) => dispatch({ type: GET_SA_MODAL_CHART_DATA, payload: [] });
};
