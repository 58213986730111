/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Link } from 'react-router-dom';

const ItemsList = ({ items, onStockClick }) => {
  return (
    <ListGroup className='list mb-0' flush>
      {items.map((item, index) => (
        <ListGroupItem
          key={index}
          data-id={item.id}
          onClick={() => onStockClick(item.stock_code)} // Tıklama olayı
          style={{ cursor: 'pointer' }} // Tıklanabilir görünüm
        >
          <div className='d-flex align-items-center'>
            <div className='flex-shrink-0 me-3'>
              <div className='avatar-sm '>
                <div className='avatar-title rounded  text-white text-center font-weight-bold'>
                  {item.stock_code}
                </div>
              </div>
            </div>
            <div className='flex-grow-1 overflow-hidden'>
              <h5 className='contact-name fs-13 mb-1'>
                <Link to='#' className='link text-body'>
                  {item.stock_code}
                  {/* `item.name` yerine uygun özellik kullanın */}
                </Link>
              </h5>
              <p className='contact-born text-muted mb-0'>
                {item.stock_name}
                {/* `item.detail` yerine uygun özellik kullanın */}
              </p>
            </div>
            <div className='flex-shrink-0 ms-2'>
              <h5 className='contact-name fs-13 mb-1 text-end'>
                {item.latestPrice && item.latestPrice.fiyat ? (
                  <Link to='#' className='link text-body'>
                    {Number(item.latestPrice.fiyat).toFixed(2)}
                  </Link>
                ) : null}
              </h5>

              <p className='contact-born text-muted text-end mb-0'>
                {item.latestPrice && item.latestPrice.yuzde ? (
                  <Link
                    to='#'
                    className={`link   ${
                      Number(item.latestPrice.yuzde) < 0 ? 'text-danger' : ''
                    }`}
                  >
                    {Number(item.latestPrice.yuzde).toFixed(2)}%
                  </Link>
                ) : null}
              </p>
            </div>
          </div>
        </ListGroupItem>
      ))}
    </ListGroup>
  );
};

ItemsList.propTypes = {
  items: PropTypes.array.isRequired,
  onStockClick: PropTypes.func.isRequired,
};

export default ItemsList;
