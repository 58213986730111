import {
  STOCK_SETTINGS_GET_STOCK_LIST,
  STOCK_SETTINGS_GET_STOCK_FILTERS,
  STOCK_SETTINGS_GET_STOCK_INFORMATIONS,
  STOCK_SETTINGS_SET_SELECTED_STOCK,
  STOCK_SETTINGS_UPDATE_STOCK_INFORMATIONS,
} from 'toolkit/constants/ActionTypes';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  error: '',
  loading: false,
  updatingContent: false,
  message: '',
  stockList: [],
  filters: [],
  loadCount: 0,
  selectedStock: null,
  stockInformations: null,
};

const stockSettingsReducer = createReducer(initialState, (builder) => {
  builder.addCase(STOCK_SETTINGS_GET_STOCK_LIST, (state, action) => {
    // Stok listesini güncelle
    state.stockList = action.payload;

    // Payload içindeki ilk elemanı kontrol et ve varsa selectedStock'a ata
    // if (action.payload && action.payload.length > 0) {
    //   state.selectedStock = action.payload[0];
    // } else {
    //   // Eğer payload boş ise, selectedStock'u null veya uygun bir default değere ayarla
    //   state.selectedStock = null;
    // }
  });
  builder.addCase(STOCK_SETTINGS_GET_STOCK_FILTERS, (state, action) => {
    state.filters = action.payload;
  });
  builder.addCase(STOCK_SETTINGS_GET_STOCK_INFORMATIONS, (state, action) => {
    state.stockInformations = action.payload;
  });
  builder.addCase(STOCK_SETTINGS_SET_SELECTED_STOCK, (state, action) => {
    state.selectedStock = action.payload;
  });
  builder.addCase(STOCK_SETTINGS_UPDATE_STOCK_INFORMATIONS, (state) => {
    state.loadCount += 1;
  });
});

export default stockSettingsReducer;
