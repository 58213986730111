import {
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  ADMIN_FINANCIAL_APP_GET_FIN_DATA,
  UPDATING_CONTENT,
  ADMIN_FINANCIAL_APP_UPDATE_FIN_DATA,
  ADMIN_FINANCIAL_APP_REORDER_FIN_ROWS,
  ADMIN_FINANCIAL_APP_UPDATE_FIN_ROW,
} from 'toolkit/constants/ActionTypes';

import jwtAxios from '@veo/services/auth/jwt-auth';
import { appIntl } from '@veo/helpers/Common';
export const adminFinancialAppGetFinData = (params) => {
  const { messages } = appIntl();
  console.log('params', params);
  return (dispatch) => {
    dispatch({ type: FETCH_START });

    // let params = { stock_code: 'BOSSA', viewmode: 'gelir-tablosu' };

    jwtAxios
      .post(`/financial-statement/generate-report`, params)

      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: ADMIN_FINANCIAL_APP_GET_FIN_DATA,
            payload: data.data.result,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error?.message || 'Hata' });
      });
  };
};

// export const adminFinancialAppGetFinDataSS=
//   (viewmode) => (dispatch, getState) => {
//     dispatch({ type: FETCH_START });

//     //  const { currentStock } = getState().stockCommon; // Örnek olarak financials kullanıldı, doğru reducer'a göre güncelleyin

//     let params = { stock_code: 'AAPL', viewmode: viewmode };

//     jwtAxios
//       .post(`/financial-statement/generate-report`, params)
//       .then((response) => {
//         if (response.status === 200) {
//           dispatch({ type: FETCH_SUCCESS });
//           dispatch({
//             type: ADMIN_FINANCIAL_APP_GET_FIN_DATA,
//             payload: data.data.result,
//           });
//         } else {
//           dispatch({
//             type: ADMIN_FINANCIAL_APP_GET_FIN_DATA,
//             payload: null,
//           });
//           // handleFetchError(dispatch, 'Bilinmeyen hata olustu.');
//         }
//       })
//       .catch((error) => {
//         dispatch({
//           type: ADMIN_FINANCIAL_APP_GET_FIN_DATA,
//           payload: null,
//         });
//         dispatch({ type: FETCH_ERROR, payload: error.message });
//       });
//   };

export const adminFinancialAppUpdateFinData = (dataToUpdate) => (dispatch) => {
  dispatch({ type: FETCH_START });

  dispatch({ type: UPDATING_CONTENT });

  jwtAxios
    .post('/financial-statement/update-report', {
      data: dataToUpdate, // Güncellenecek veriler
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch({ type: FETCH_SUCCESS });
        // Burada Redux state'ini güncelleme işlemini yapabilirsiniz
        dispatch({
          type: ADMIN_FINANCIAL_APP_UPDATE_FIN_DATA, // Yeni eylem tipi
          payload: response.data.updatedItems, // Güncellenmiş veriler
        });
      } else {
        dispatch({
          type: ADMIN_FINANCIAL_APP_UPDATE_FIN_DATA,
          payload: null,
        });
        dispatch({ type: FETCH_ERROR, payload: 'HATA' });
      }
    })
    .catch((error) => {
      dispatch({ type: FETCH_ERROR, payload: error.message });
    });
};

export const adminFinacialAppReorderFinRows = (draggedId, targetId) => ({
  type: ADMIN_FINANCIAL_APP_REORDER_FIN_ROWS,
  payload: { draggedId, targetId },
});

export const adminFinacialAppUpdateFinRow = (data) => {
  return (dispatch) =>
    dispatch({ type: ADMIN_FINANCIAL_APP_UPDATE_FIN_ROW, payload: data });
};
