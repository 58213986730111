import { combineReducers } from 'redux';
import Settings from './Setting';
import Common from './Common';
import Scheduler from './Scheduler';
import StockSettings from './StockSettings'; //from 'modules/admin/StockSettings';tockSettings
import StockCommon from './StockCommon';
import StockAnlysis from './StockAnalysis';
import AdminFinacialApp from './AdminFinacialApp';
import StockTargetPrice from './StockTargetPrice';

const reducers = () =>
  combineReducers({
    settings: Settings,
    common: Common,
    scheduler: Scheduler,
    stockSettings: StockSettings,
    stockCommon: StockCommon,
    stockAnlysis: StockAnlysis,
    adminFinacialApp: AdminFinacialApp,
    stockTargetPrice: StockTargetPrice,
  });
export default reducers;
