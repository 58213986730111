import {
  GET_STOCK_COMMON_STOCK_LIST,
  SET_STOCK_COMMON_CURRENT_STOCK_CODE,
  CHECK_STOCK_COMMON_STOCK_VALIDITY,
  GET_STOCK_COMMON_FILTERS,
} from 'toolkit/constants/ActionTypes';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  stockList: [],
  currentStockCode: '',
  isValid: false,
  filters: {},
};

const stockCommonReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(GET_STOCK_COMMON_STOCK_LIST, (state, action) => {
      // Stok listesini güncelle
      state.stockList = action.payload;

      // Payload içindeki ilk elemanı kontrol et ve varsa selectedStock'a ata
      // if (action.payload && action.payload.length > 0) {
      //   state.selectedStock = action.payload[0];
      // } else {
      //   // Eğer payload boş ise, selectedStock'u null veya uygun bir default değere ayarla
      //   state.selectedStock = null;
      // }
    })
    .addCase(SET_STOCK_COMMON_CURRENT_STOCK_CODE, (state, action) => {
      state.currentStockCode = action.payload;
    })
    .addCase(GET_STOCK_COMMON_FILTERS, (state, action) => {
      state.filters = action.payload;
    })

    .addCase(CHECK_STOCK_COMMON_STOCK_VALIDITY, (state, action) => {
      console.log('state', state);
      state.isValid = action.payload.isValid;
      state.stockInformations = action.payload.stockInformations;
      state.currentStockCode =
        action.payload.stockInformations?.stock_code || '';
    });
});

export default stockCommonReducer;
