import {
  GET_SA_DASHBORAD_DATA,
  SET_SA_NUMBER_OF_QUARTER,
  SET_SA_HIDE_TOP_BOX,
  SET_SA_SHOW_AVG,
  SET_SA_EM,
  GET_SA_PRICE_LIST,
  GET_SA_STOCK_COMPARISON_DATA,
  GET_SA_MODAL_CHART_DATA,
} from 'toolkit/constants/ActionTypes';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  dashboardData: [],
  numberofQuarter: 5,
  hideTopBox: true,
  showAvg: false,
  em: true,
  priceList: [],
  stockComparisonData: [],
  modalChartData: [],
};

const stockAnalysisReducer = createReducer(initialState, (builder) => {
  builder.addCase(GET_SA_DASHBORAD_DATA, (state, action) => {
    state.dashboardData = action.payload;
  });
  builder.addCase(GET_SA_PRICE_LIST, (state, action) => {
    state.priceList = action.payload;
  });
  builder.addCase(GET_SA_STOCK_COMPARISON_DATA, (state, action) => {
    state.stockComparisonData = action.payload;
  });
  builder.addCase(SET_SA_NUMBER_OF_QUARTER, (state, action) => {
    state.numberofQuarter = action.payload;
  });
  builder.addCase(SET_SA_HIDE_TOP_BOX, (state) => {
    state.hideTopBox = !state.hideTopBox;
  });
  builder.addCase(SET_SA_SHOW_AVG, (state) => {
    state.showAvg = !state.showAvg;
  });
  builder.addCase(GET_SA_MODAL_CHART_DATA, (state, action) => {
    state.modalChartData = action.payload;
  });
  builder.addCase(SET_SA_EM, (state) => {
    state.em = !state.em;
  });
});

export default stockAnalysisReducer;
