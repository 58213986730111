import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Joyride from 'react-joyride';
import Cookies from 'js-cookie'; // js-cookie paketini yükleyin

function AppPageGuide({ target, content }) {
  const [runJoyride, setRunJoyride] = useState(false);
  const cookieName = `joyrideCompleted-${target.replace(/[^a-zA-Z0-9]/g, '_')}`; // target'i kullanarak dinamik bir cookie ismi oluştur

  useEffect(() => {
    const joyrideCompleted = Cookies.get(cookieName);
    if (!joyrideCompleted) {
      setRunJoyride(true);
    }
  }, [cookieName]);

  const handleJoyrideCallback = (data) => {
    if (data.status === 'finished') {
      Cookies.set(cookieName, 'true', { expires: 7 }); // 7 gün süreyle cookie'ye kaydet
      setRunJoyride(false);
    }
  };

  return (
    <Joyride
      run={runJoyride}
      callback={handleJoyrideCallback}
      steps={[
        {
          content: content,
          target: target,
        },
      ]}
    />
  );
}

AppPageGuide.propTypes = {
  target: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default AppPageGuide;
