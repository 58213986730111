import { createReducer } from '@reduxjs/toolkit';
import {
  SCH_GET_SCHEDULER_LIST,
  SCH_GET_SCHEDULER_LOG_LIST,
  SCH_GET_SCHEDULER_METHOD_LIST,
  SCH_GET_SCHEDULER_STATUS_LIST,
  SCH_GET_SCHEDULER_TASK,
  SCH_CREATE_SCHEDULER_TASK,
  SCH_UPDATE_SCHEDULER_TASK,
  SCH_UPDATING_CONTENT,
  SCH_FETCH_SUCCESS,
  SCH_DELETE_SCHEDULER_TASK,
  SCH_FETCH_START,
  SCH_FETCH_ERROR,
  SCH_RUN_SCHEDULER_TASK,
  SCH_STOP_SCHEDULER_TASK,
} from 'toolkit/constants/ActionTypes';

const initialState = {
  error: '',
  loading: false,
  updatingContent: false,
  message: '',
  schedulerList: {
    items: [],
    meta: [],
  },
  schedulerLogList: {
    items: [],
    meta: [],
  },
  schedulerMethodList: [],
  schedulerStatusList: [],
  schedulerTask: {},
  newTaskId: false,
  loadCount: 0,
};
const schedulerReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(SCH_UPDATING_CONTENT, (state) => {
      state.error = '';
      state.message = '';
      state.updatingContent = true;
    })
    .addCase(SCH_FETCH_SUCCESS, (state) => {
      state.error = '';
      state.message = '';
      state.loading = false;
      state.updatingContent = false;
    })
    .addCase(SCH_GET_SCHEDULER_LIST, (state, action) => {
      state.schedulerList = action.payload || initialState.schedulerList;
    })
    .addCase(SCH_GET_SCHEDULER_LOG_LIST, (state, action) => {
      state.schedulerLogList = action.payload || initialState.schedulerLogList;
    })
    .addCase(SCH_GET_SCHEDULER_METHOD_LIST, (state, action) => {
      state.schedulerMethodList =
        action.payload || initialState.schedulerMethodList;
    })
    .addCase(SCH_GET_SCHEDULER_STATUS_LIST, (state, action) => {
      state.schedulerStatusList =
        action.payload || initialState.schedulerStatusList;
    })
    .addCase(SCH_GET_SCHEDULER_TASK, (state, action) => {
      state.schedulerTask = action.payload || {};
      state.newTaskId = null;
    })
    .addCase(SCH_CREATE_SCHEDULER_TASK, (state, action) => {
      state.schedulerTask = action.payload || {};
      state.schedulerList.items.push(action.payload);
      state.newTaskId = action.payload?.id || false;
    })
    .addCase(SCH_UPDATE_SCHEDULER_TASK, (state, action) => {
      state.schedulerList.items = state.schedulerList.items.map((item) =>
        item.id === action.payload.id ? { ...item, ...action.payload } : item,
      );
    })
    .addCase(SCH_FETCH_START, (state) => {
      state.loading = true;
    })
    .addCase(SCH_FETCH_ERROR, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.message = '';
      state.updatingContent = false;
    })
    .addCase(SCH_RUN_SCHEDULER_TASK, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.message = '';
      state.updatingContent = false;
      state.loadCount += 1;
    })
    .addCase(SCH_STOP_SCHEDULER_TASK, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.message = '';
      state.updatingContent = false;
      state.loadCount += 1;
    })
    .addCase(SCH_DELETE_SCHEDULER_TASK, (state, action) => {
      state.schedulerList.items = state.schedulerList.items.filter(
        (item) => item.id !== action.payload.id,
      );
    });
});

export default schedulerReducer;
