import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import { checkPermission } from '@veo/helpers/RouteHelper';

const SubMenuItem = ({ subItem, userRole }) => {
  // Eğer subItem için izin yoksa, render etme
  if (!checkPermission(subItem.permittedRole, userRole)) {
    return null;
  }

  if (!subItem.isChildItem) {
    return (
      <li className='nav-item'>
        <Link to={subItem.link ? subItem.link : '/#'} className='nav-link'>
          {subItem.label}
          {subItem.badgeName && (
            <span
              className={`badge badge-pill bg-${subItem.badgeColor}`}
              data-key='t-new'
            >
              {subItem.badgeName}
            </span>
          )}
        </Link>
      </li>
    );
  } else {
    return (
      <li className='nav-item'>
        <Link
          onClick={subItem.click}
          className='nav-link'
          to='/#'
          data-bs-toggle='collapse'
        >
          {subItem.label}
          {subItem.badgeName && (
            <span
              className={`badge badge-pill bg-${subItem.badgeColor}`}
              data-key='t-new'
            >
              {subItem.badgeName}
            </span>
          )}
        </Link>
        <Collapse
          className='menu-dropdown'
          isOpen={subItem.stateVariables}
          id='sidebarEcommerce'
        >
          <ul className='nav nav-sm flex-column'>
            {subItem.childItems &&
              subItem.childItems.map((childItem, key) => (
                <SubMenuItem
                  key={key}
                  subItem={childItem}
                  userRole={userRole}
                /> // İç içe SubMenuItem kullanımı
              ))}
          </ul>
        </Collapse>
      </li>
    );
  }
};

SubMenuItem.propTypes = {
  subItem: PropTypes.object.isRequired,
  userRole: PropTypes.array,
};

export default SubMenuItem;
