import PropTypes from 'prop-types';

const MenuHeader = ({ label }) => (
  <li className='menu-title'>
    <span data-key='t-menu'>{label}</span>
    {/* <div className='d-flex align-items-center m-2'>
      <div className='avatar-sm  rounded p-1 me-2'>
        <img
          src='https://api.hta.com/images/hisse-logo/bossa.png'
          alt=''
          className='img-fluid d-block'
        ></img>
      </div>
      <div>
        <h5 className='fs-14 my-1'>
          <a
            className='  text-white'
            href='/velzon/react/master/apps-ecommerce-product-details'
          >
            BOSSA
          </a>
        </h5>
        <span className=' text-white  p-0'>18:09:520</span>
      </div>
      <div className='ms-auto'>
        <h5 className='fs-14 my-1'>
          <a
            className='text-white'
            href='/velzon/react/master/apps-ecommerce-product-details'
          >
            123.23 &#8378;
          </a>
        </h5>
        <span className=' text-white p-0'>%3.23</span>
      </div>
    </div> */}
  </li>
);

MenuHeader.propTypes = {
  label: PropTypes.string,
};

export default MenuHeader;
