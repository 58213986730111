import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Container, Row, Alert } from 'reactstrap';

import AppPromptModal from '@veo/components/AppPromptModal';
import FinTable from './FinTable';
import AppLoader from '@veo/components/AppLoader';
import AppStockView from '@veo/components/AppStockView';
import {
  adminFinancialAppGetFinData,
  adminFinancialAppUpdateFinData,
} from 'toolkit/actions/';
const Financials = () => {
  const { viewmode } = useParams(); // URL'den 'viewmode' parametresini al
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentStockCode } = useSelector(({ stockCommon }) => stockCommon);

  const { updatingContent = {} } = useSelector(({ common }) => common);

  const [showAll, setShowAll] = useState(false);
  const [refreshModal, setRefreshModal] = useState(false);
  const { col, items, isDirty } = useSelector(({ adminFinacialApp }) => ({
    col: adminFinacialApp.finData?.col || [],
    items: adminFinacialApp.finData?.items || [],
    isDirty: adminFinacialApp?.isDirty || false,
  }));

  const title = useMemo(() => {
    const titles = {
      'nakit-akim-tablosu': 'Nakit Akım Tablosu',
      'gelir-tablosu': 'Gelir Tablosu',
      bilanco: 'Bilanço',
    };

    // Parametre değerini başlık formatına çevir
    return titles[viewmode] || 'Bilinmeyen Tablo';
  }, [viewmode]);
  const reloadData = () => {
    // Verileri yeniden yükleme işlemi burada yapılacak
    if (currentStockCode) {
      let params = {
        viewmode: viewmode,
        stock_code: currentStockCode,
      };
      console.log('params', params);
      dispatch(
        adminFinancialAppGetFinData({
          viewmode: viewmode,
          stock_code: currentStockCode,
        }),
      );
    }
    setRefreshModal(false);
  };

  useEffect(() => {
    dispatch(
      adminFinancialAppGetFinData({
        viewmode: viewmode,
        stock_code: currentStockCode,
      }),
    );
  }, [currentStockCode, viewmode]);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };
  // Burada, 'viewmode' varlığını kontrol edebilirsiniz. Eğer yoksa hata sayfasına yönlendirme yapabilirsiniz.
  useEffect(() => {
    if (!viewmode) {
      navigate('/error-pages/error-404');
    }
  }, [viewmode, navigate]);
  const handleSave = () => {
    // Sadece isDirty özelliği true olan öğeleri filtrele
    const dirtyItems = items
      .filter((item) => item.isDirty)
      .map((item) => ({
        id: item.id,
        label: item.label,
        sort_order: item.sort_order,
        taxonomy_title_pos: item.taxonomy_title_pos,
        is_disabled: item.is_disabled,
      }));

    // Filtrelenen öğeleri güncelleme eylemiyle gönder
    if (dirtyItems.length > 0) {
      dispatch(adminFinancialAppUpdateFinData(dirtyItems));
    }
  };
  // 'viewmode' varsa, onu ekranda göster
  return (
    <div className='hta-finacial-table'>
      <AppStockView>
        <Container fluid>
          {updatingContent && <AppLoader></AppLoader>}
          {refreshModal && (
            <AppPromptModal
              title='Yeniden yükle'
              subject='Yaptığınz tüm değişiklikler iptal edielcek! emin misininiz?'
              show={refreshModal}
              onDeleteClick={() => reloadData()}
              onCloseClick={() => setRefreshModal(false)}
            />
          )}
          {!currentStockCode && (
            <Alert color='warning'>
              Lütfen bir stok kodu seçip yeniden deneyiniz.
            </Alert>
          )}
          <Row className='g-2  page-title-box'>
            <div className='d-flex align-items-center'>
              <h5 className='card-title mb-0 flex-grow-1'>
                {title} - {currentStockCode}
              </h5>
              <div className='flex-shrink-0'>
                <div className='list-grid-nav hstack gap-1'>
                  <Button
                    color='primary'
                    className='btn btn-primary'
                    onClick={() =>
                      window.open(
                        `https://api.hissetemelanaliz.com/site/bilanco-indir/${currentStockCode}`,
                        '_blank',
                      )
                    }
                  >
                    Bilanço İndir
                  </Button>

                  {isDirty && (
                    <Button
                      color='info'
                      id='grid-view-button'
                      className='btn btn-soft-info   btn-icon fs-14  filter-button'
                      onClick={() => setRefreshModal(true)}
                    >
                      <i className='  ri-refresh-line ri-xl'></i>
                    </Button>
                  )}
                  <Button
                    color='info'
                    id='list-view-button'
                    className='btn btn-soft-info nav-link  btn-icon fs-14 filter-button'
                    onClick={toggleShowAll}
                  >
                    <i
                      className={
                        showAll ? 'ri-eye-off-line ri-xl' : 'ri-eye-line ri-xl'
                      }
                    ></i>
                  </Button>
                  {isDirty && (
                    <Button className='btn btn-primary' onClick={handleSave}>
                      <i className='ri-save-fill me-1 align-bottom'></i> Kaydet
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </Row>

          <Row>
            <FinTable data={items} columns={col} showAll={showAll} />
          </Row>
        </Container>
      </AppStockView>
    </div>
  );
};

export default Financials;
