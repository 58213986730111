// import React from 'react';
import { ReactComponent as Logo } from '../../../../assets/icon/something-wrong.svg';
// import { useTheme } from '@mui/material';

// const ErrorIcon = () => {
//   const theme = useTheme();
//   return <Logo fill={theme.palette.primary.main} />;
// };

// export default ErrorIcon;
const ErrorIcon = () => {
  return <Logo style={{ fill: 'var(--bs-primary) ', width: '50%' }} />;
};
export default ErrorIcon;
