/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AppFormatter from '@veo/components/AppFormatter';
import useTradingViewModal from '@veo/hooks/useTradingViewModal';
import AppTradingViewModal from '../AppTradingViewModal';
import AppPageGuide from '../AppPageGuide';
const TradingViewPerformance = ({ stockCode }) => {
  const {
    isModalOpen: isTradingViewModalOpen,

    openModal: openTradingViewModal,
    toggleModal: toggleTradingViewModal,
  } = useTradingViewModal();
  const { stockInformations } = useSelector(({ stockCommon }) => stockCommon);
  const fiilidolasim =
    stockInformations?.stockPublicFloat?.dolasimdaki_pay_orani || 'N/A';
  const dolasimdaki_pay_tutari =
    stockInformations?.stockPublicFloat?.dolasimdaki_pay_tutari || 0;

  const [performanceData, setPerformanceData] = useState(null);

  const fieldsOrder = [
    { label: 'Fiyat', field: 'close' },
    { label: 'Bugün', field: 'change' },
    { label: 'Haftalık', field: 'Perf.W' },
    { label: '1 ay', field: 'Perf.1M' },
    { label: '3 ay', field: 'Perf.3M' },
    { label: '6 ay', field: 'Perf.6M' },
    { label: 'Güncel yıl', field: 'Perf.YTD' },
    { label: '1 yıl', field: 'Perf.Y' },
  ];

  useEffect(() => {
    let intervalId;

    // Define the fetchData function
    const fetchData = async () => {
      if (stockCode) {
        const fieldsString = fieldsOrder.map((item) => item.field).join(',');
        const url = `https://scanner.tradingview.com/symbol?symbol=BIST:${stockCode}&fields=${fieldsString}`;

        try {
          const response = await fetch(url);
          const data = await response.json();
          setPerformanceData(data || null);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    // Immediately invoke fetchData
    fetchData();

    // Set an interval to repeatedly invoke fetchData
    if (stockCode) {
      intervalId = setInterval(fetchData, 15000);
    }

    // Clear the interval when the component unmounts or the stockcode changes
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [stockCode]); // Dependencies array with stockcode

  // const renderPerformanceItem = (label, value) => (
  //   <div className='pe-3'>
  //     <h6 className='mb-2 text-muted'>{label}</h6>
  //     <h5 className={value >= 0 ? 'text-success mb-0' : 'text-danger mb-0'}>
  //       {value.toFixed(2)}%
  //     </h5>
  //   </div>
  // );

  if (!performanceData) {
    return null;
  }

  return (
    <>
      <AppTradingViewModal
        isOpen={isTradingViewModalOpen}
        toggle={toggleTradingViewModal}
        stockCode={stockCode}
      />
      <AppPageGuide
        target='.fiyat'
        content='TradingView Chart acmak için Fiyata tıklamanız yeterlidir. Açılan sayfayı X ile kapatıp tekrar bu ekrana dönebilirsiniz'
      />
      <div className='d-flex justify-content-end text-end flex-wrap gap-1'>
        {fieldsOrder.map((item) => {
          const value = performanceData[item.field];
          let formattedValue;
          let valueClass = 'mb-0';

          // "Fiyat" için özel stil ve formatlama
          if (item.label === 'Fiyat') {
            formattedValue = value !== null ? `${value.toFixed(2)} ₺` : 'N/A'; // Burada para birimi olarak USD varsayılmıştır, gerekirse değiştirin
            valueClass += ' text-success'; // Daha büyük font boyutu için
          } else {
            // Diğer durumlar için formatlama
            formattedValue =
              value !== null
                ? `% ${value >= 0 ? '+' : ''}${value.toFixed(2)}`
                : 'N/A';
            valueClass += value >= 0 ? ' text-success' : ' text-danger';
          }

          return (
            <>
              {item.label === 'Fiyat' ? (
                <div
                  key={item.field}
                  className='fiyat text-end ps-3 pe-1 py-1 cursor-pointer'
                  onClick={() => openTradingViewModal(stockCode)}
                >
                  <h6 className='mb-2 text-muted'>
                    {item.label}

                    <i className='ms-2 ri-line-chart-fill text-muted fs-16 align-middle me-1'></i>
                  </h6>
                  <h5 className={valueClass}>{formattedValue}</h5>
                </div>
              ) : (
                <div key={item.field} className='text-end ps-3 pe-1 py-1'>
                  <h6 className='mb-2 text-muted'>{item.label}</h6>
                  <h5 className={valueClass}>{formattedValue}</h5>
                </div>
              )}
            </>
          );
        })}
        <div className='vr'></div>
        <div className='text-end  ps-3 pe-1 py-1   '>
          <h6 className='mb-2 text-muted'>Lot Sayısı</h6>
          <h5 className='text-success'>
            <AppFormatter
              value={dolasimdaki_pay_tutari}
              format={{ type: 'number', decimal: 0 }}
              Tag='span'
            />
          </h5>
        </div>
        <div className='vr'></div>
        <div className='text-end  ps-3 pe-1 py-1   '>
          <h6 className='mb-2 text-muted'>Fiili Dolaşım Oranı</h6>
          <h5 className='text-success'> {fiilidolasim} %</h5>
        </div>
      </div>
    </>
  );
};

export default TradingViewPerformance;
