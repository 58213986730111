import React, { useState, useEffect, useMemo } from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch } from 'react-redux';

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { useTableColumns } from './TableColumns';
import DraggableRow from './DraggableRow';
import { adminFinacialAppReorderFinRows } from 'toolkit/actions';

// eslint-disable-next-line react/prop-types
const FinTable = ({ data, columns, showAll }) => {
  const dispatch = useDispatch();
  const [records, setRecords] = useState(data);

  useEffect(() => {
    setRecords(data);
  }, [data]);
  const tableColumns = useTableColumns(columns);

  const reorderRow = (draggedId, targetId) => {
    dispatch(adminFinacialAppReorderFinRows(draggedId, targetId));
  };
  const filteredAndSortedRecords = useMemo(() => {
    // İlk olarak is_disabled alanına göre filtrele
    const filtered = showAll
      ? records
      : records.filter((item) => !item.is_disabled);
    // Sonra sort_order'a göre sırala
    return [...filtered].sort((a, b) => a.sort_order - b.sort_order);
  }, [records, showAll]);

  const table = useReactTable({
    data: filteredAndSortedRecords,
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id, //good to have guaranteed unique row ids/keys for rendering
  });
  if (!columns) {
    return 'NA';
  }
  return (
    <Card>
      <CardBody className='bg-light h-100 p-0 pt-1 '>
        <DndProvider backend={HTML5Backend}>
          <div className='fintable-wrapper'>
            <Table bordered hover>
              <thead className='sticky-top top--2 bg-light'>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    <th />
                    {headerGroup.headers.map((header) => (
                      <th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <DraggableRow
                    key={row.id}
                    row={row}
                    reorderRow={reorderRow}
                  />
                ))}
              </tbody>
              {/* <tfoot>
                {table.getFooterGroups().map((footerGroup) => (
                  <tr key={footerGroup.id}>
                    {footerGroup.headers.map((header) => (
                      <th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.footer,
                              header.getContext(),
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </tfoot> */}
            </Table>
          </div>
        </DndProvider>
      </CardBody>
    </Card>
  );
};

export default FinTable;
