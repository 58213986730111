import React from 'react';
import { RoutePermittedRole } from '@veo/constants/AppEnums';

const Scheduler = React.lazy(() => import('./Scheduler'));
const StockSettings = React.lazy(() => import('./StockSettings'));
const FinancialDataSectorCodesTable = React.lazy(
  () => import('./FinancialDataSectorCodesTable'),
);
// const FinTableSettings= React.lazy(()=>import('./FinTableSettings'));
import Financials from './Financials';

export const adminPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/scheduler/:viewmode/:id',
    element: <Scheduler />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/scheduler/:viewmode',
    element: <Scheduler />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/stock-settings',
    element: <StockSettings />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/bilanco',
    element: <StockSettings />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/finansal-tablolar/:viewmode/:stockCode',
    element: <Financials />,
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/financial_data_sector_codes',
    element: <FinancialDataSectorCodesTable />,
  },
];
