import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import { checkPermission } from '@veo/helpers/RouteHelper';
import SubMenuItem from './SubMenuItem';
const NavItem = ({ item, userRole }) => {
  // Check permission for the item
  if (!checkPermission(item.permittedRole, userRole)) {
    return null;
  }
  if (item.subItems) {
    return (
      <li className='nav-item'>
        <Link
          onClick={item.click}
          className='nav-link menu-link'
          to={item.link ? item.link : '/#'}
          data-bs-toggle='collapse'
        >
          <i className={item.icon}></i>
          <span data-key='t-apps'>{item.label}</span>
          {item.badgeName ? (
            <span
              className={'badge badge-pill bg-' + item.badgeColor}
              data-key='t-new'
            >
              {item.badgeName}
            </span>
          ) : null}
        </Link>
        <Collapse
          className='menu-dropdown'
          isOpen={item.stateVariables}
          id='sidebarApps'
        >
          <ul className='nav nav-sm flex-column test'>
            {item.subItems &&
              item.subItems.map((subItem, key) => (
                <SubMenuItem key={key} subItem={subItem} userRole={userRole} />
              ))}
          </ul>
        </Collapse>
      </li>
    );
  } else {
    return (
      <li className='nav-item'>
        <Link className='nav-link menu-link' to={item.link ? item.link : '/#'}>
          <i className={item.icon}></i> <span>{item.label}</span>
          {item.badgeName ? (
            <span
              className={'badge badge-pill bg-' + item.badgeColor}
              data-key='t-new'
            >
              {item.badgeName}
            </span>
          ) : null}
        </Link>
      </li>
    );
  }
};

NavItem.propTypes = {
  item: PropTypes.object.isRequired,
  userRole: PropTypes.array,
};

export default NavItem;
