import React, { useState } from 'react';

import { Input } from 'reactstrap';
import AppStockSearchModal from '../AppStockSearchModal';

//import images

const AppStockSearch = () => {
  const [value, setValue] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const onChangeData = (value) => {
    setValue(value);
  };
  const handleStockSelected = (value) => {
    setValue(value);
    setIsModalOpen(false);
  };

  return (
    <React.Fragment>
      <form className='app-search d-none d-md-block'>
        <div className='position-relative'>
          <Input
            type='text'
            className='form-control'
            placeholder='Hisse ara...'
            id='search-options'
            value={value}
            readOnly
            onChange={(e) => {
              onChangeData(e.target.value);
            }}
          />
          <span className='mdi mdi-magnify search-widget-icon'></span>
          <span
            className='mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none'
            id='search-close-options'
          ></span>

          {/* Yeni eklenen tıklanabilir div */}
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              opacity: 0,
              cursor: 'pointer', // İmleci tıklanabilir olarak değiştir
            }}
            onClick={() => {
              setIsModalOpen(true);
            }}
          ></div>
        </div>
      </form>
      <AppStockSearchModal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(!isModalOpen)}
        onStockSelect={handleStockSelected}
      />
    </React.Fragment>
  );
};

export default AppStockSearch;
