// TableColumns.js
import React from 'react';
import { useDispatch } from 'react-redux';
import { createColumnHelper } from '@tanstack/react-table';
import { formatNumber } from '@veo/helpers/Utils';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { adminFinacialAppUpdateFinRow } from 'toolkit/actions/';

import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';

// Define the base column structure
const columnHelper = createColumnHelper();

// A custom cell component for editable cells
const EditableCell = ({ record, value: initialValue, dispatch }) => {
  const [value, setValue] = React.useState(initialValue);
  const [editable, setEditable] = React.useState(false);

  const onBlur = () => {
    setEditable(false);
    const updatedRecord = {
      ...record,
      label: value,
    };

    dispatch(
      adminFinacialAppUpdateFinRow({
        id: record.id,
        isDirty: true,
        record: updatedRecord,
      }),
    );
  };

  const onDoubleClick = () => {
    setEditable(true);
  };

  let styles = {
    paddingLeft: record.taxonomy_title_pos * 15,
    textAlign: 'left',
  };

  return editable ? (
    <input
      style={styles}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={onBlur}
    />
  ) : (
    <div onDoubleClick={onDoubleClick} style={styles}>
      {value}
    </div>
  );
};

// Export the function that uses useMemo to ensure the columns are only recalculated when necessary
export const useTableColumns = (dynamicColumnsData) => {
  const dispatch = useDispatch();
  const baseColumns = [
    columnHelper.accessor('actions', {
      id: 'actions',
      width: 50,
      header: () => <span>Actions</span>,
      cell: (info) => {
        let record = info.row.original;
        return (
          <ul className='list-inline hstack gap-0 mb-0'>
            <li
              className='list-inline-item edit'
              title='Azalt'
              onClick={() => {
                if (record.taxonomy_title_pos > 0) {
                  const updatedRecord = {
                    ...record,
                    isDirty: true,
                    taxonomy_title_pos: record.taxonomy_title_pos - 1,
                  };

                  dispatch(
                    adminFinacialAppUpdateFinRow({
                      id: record.id,
                      isDirty: true,
                      record: updatedRecord,
                    }),
                  );
                } else {
                  console.log('taxonomy_title_pos 0 dan küçük olamaz');
                }
              }}
            >
              <Link to='#' className='text-muted d-inline-block'>
                <i className='ri-indent-decrease fs-16'></i>
              </Link>
            </li>
            <li
              className='list-inline-item edit'
              title='Arttır'
              onClick={() => {
                const updatedRecord = {
                  ...record,
                  isDirty: true,
                  taxonomy_title_pos: record.taxonomy_title_pos + 1,
                };
                dispatch(
                  adminFinacialAppUpdateFinRow({
                    id: record.id,
                    isDirty: true,
                    record: updatedRecord,
                  }),
                );
              }}
            >
              <Link to='#' className='text-muted d-inline-block'>
                <i className='ri-indent-increase fs-16'></i>
              </Link>
            </li>
            <li
              className='list-inline-item edit'
              title='Görünürlüğü Değiştir'
              onClick={() => {
                const updatedRecord = {
                  ...record,
                  isDirty: true,
                  is_disabled: !record.is_disabled,
                };

                dispatch(
                  adminFinacialAppUpdateFinRow({
                    id: record.id,
                    isDirty: true,
                    record: updatedRecord,
                  }),
                );
              }}
            >
              <Link to='#' className='text-muted d-inline-block'>
                <i
                  className={
                    record.is_disabled
                      ? 'ri-eye-off-fill fs-16'
                      : 'ri-eye-fill fs-16'
                  }
                ></i>
              </Link>
            </li>
          </ul>
        );
      },
    }),
    columnHelper.accessor('label', {
      width: 200,
      header: () => (
        <div style={{ textAlign: 'left' }}>
          <span>Kalem Adı</span>
        </div>
      ),
      cell: (info) => {
        let record = info.row.original;
        return (
          <EditableCell
            record={record}
            value={record.label}
            dispatch={dispatch}
          />
        );
      },
    }),
    // ... other base columns
  ];

  // Define a function to generate dynamic columns
  const getDynamicColumns = (columns) => {
    return columns.slice(0, 6).map((col) =>
      columnHelper.accessor(col.period.toString(), {
        width: 150,
        cell: (info) => (
          <div style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
            {formatNumber(info.getValue())}
          </div>
        ),
        header: () => (
          <div className='d-flex justify-content-end align-items-center'>
            {/* Dropdown for the icon */}
            <UncontrolledDropdown>
              <DropdownToggle
                tag='a'
                className='text-reset dropdown-btn'
                role='button'
              >
                <i className='ri-folder-open-line fs-18'></i>
              </DropdownToggle>
              <DropdownMenu className='dropdown-menu-end'>
                <DropdownItem href={col.disclosure_url} target='_blank'>
                  <i className='bx bx-file text-info align-center'></i> Kap
                  Bildirim
                </DropdownItem>
                <DropdownItem href={col.pdf_url} target='_blank'>
                  <i className='bx bxs-file-pdf text-info align-center'></i>{' '}
                  Bilanço
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            {/* Label */}
            <span className='ms-3'>{col.periodLabel}</span>
          </div>
        ),
        footer: (info) => info.column.id,
      }),
    );
  };
  return React.useMemo(
    () => [
      ...baseColumns,
      ...getDynamicColumns(dynamicColumnsData),
      // ... potentially more columns
    ],
    [dynamicColumnsData, dispatch],
  );
};

EditableCell.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    taxonomy_title_pos: PropTypes.number.isRequired,
    is_disabled: PropTypes.bool.isRequired,
  }).isRequired,
  value: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};
