import {
  ADMIN_FINANCIAL_APP_GET_FIN_DATA,
  ADMIN_FINANCIAL_APP_UPDATE_FIN_DATA,
  ADMIN_FINANCIAL_APP_REORDER_FIN_ROWS,
  ADMIN_FINANCIAL_APP_UPDATE_FIN_ROW,
} from 'toolkit/constants/ActionTypes';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  finData: [],
  isDirty: false,
  updatingContent: false,
};

const adminFinancialAppReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(ADMIN_FINANCIAL_APP_GET_FIN_DATA, (state, action) => {
      state.finData = action.payload;
      state.isDirty = false;
    })
    .addCase(ADMIN_FINANCIAL_APP_UPDATE_FIN_DATA, (state) => {
      // const { id, record } = action.payload;
      // const updatedRecord = { ...record, isDirty: 1 };
      // const updatedItems = state.finData.items.map((item) =>
      //   item.id === id ? updatedRecord : item,
      // );

      return {
        ...state,
        // finData: {
        //   ...state.finData,
        //   items: updatedItems,
        // },
        isDirty: false,
      };
    })
    .addCase(ADMIN_FINANCIAL_APP_REORDER_FIN_ROWS, (state, action) => {
      const { draggedId, targetId } = action.payload;
      const items = state.finData.items || [];

      const draggedIndex = items.findIndex((item) => item.id === draggedId);
      const targetIndex = items.findIndex((item) => item.id === targetId);

      if (draggedIndex === -1 || targetIndex === -1) {
        console.error('Dragged or target item not found.');
        return state;
      }

      const newItems = [...items];
      const [draggedItem] = newItems.splice(draggedIndex, 1);
      newItems.splice(targetIndex, 0, draggedItem);

      const updatedItems = newItems.map((item, index) => ({
        ...item,
        isDirty: true,
        sort_order: index + 1,
      }));

      return {
        ...state,
        isDirty: true,
        finData: {
          ...state.finData,
          items: updatedItems,
        },
      };
    })
    .addCase(ADMIN_FINANCIAL_APP_UPDATE_FIN_ROW, (state, action) => {
      const { id, record } = action.payload;
      const updatedRecord = { ...record, isDirty: 1 };
      const updatedItems = state.finData.items.map((item) =>
        item.id === id ? updatedRecord : item,
      );

      return {
        ...state,
        finData: {
          ...state.finData,
          items: updatedItems,
        },
        isDirty: true,
      };
    });
});

export default adminFinancialAppReducer;
