import { useState, useCallback } from 'react';

const useTradingViewModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stockCode, setStockCode] = useState('');

  const openModal = useCallback((code) => {
    setStockCode(code);
    setIsModalOpen(true);
  }, []);

  const toggleModal = useCallback(() => {
    setIsModalOpen((prev) => !prev);
  }, []);

  return { isModalOpen, stockCode, openModal, toggleModal };
};

export default useTradingViewModal;
