//API
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const TOGGLE_APP_DRAWER = 'toggle_app_drawer';
export const UPDATING_CONTENT = 'updating_content';
export const SET_USER = 'set_user';
export const COMMON_SET_SEARCH_STOCK = 'common_set_search_stock';
export const COMMON_SET_SELECTED_STOCK = 'common_set_selected_stock';
export const COMMON_STOCK_VALIDITY_CHECK = 'common_stock_validity_check';

//APP SETTING
export const TOGGLE_NAV_COLLAPSED = 'toggle_nav_collapsed';
export const SET_INITIAL_PATH = 'set_initial_path';

export const CHANGE_SIDEBAR_THEME = 'change_sidebar_theme';
export const CHANGE_LAYOUT_MODE = 'change_layout_mode';
export const CHANGE_LAYOUT = 'chnage_layout';
export const CHANGE_LAYOUT_WIDTH = 'change_layout_width';
export const CHANGE_LAYOUT_POSITION_TYPE = 'change_layout_postion_type';
export const CHANGE_LAYOUT_LEFT_SIDEBAR_SIZE_TYPE =
  'change_layout_sidebar_size_type';

export const CHANGE_SIDEBAR_VISIBILITY_TYPE = 'change_sidebar_visibility_type';

//AUTH0
//Scheduler List
export const SCH_GET_SCHEDULER_LIST = 'sch_get_scheduler_list';
export const SCH_GET_SCHEDULER_LOG_LIST = 'sch_get_scheduler_log_list';
export const SCH_GET_SCHEDULER_METHOD_LIST = 'sch_get_scheduler_method_list';
export const SCH_GET_SCHEDULER_STATUS_LIST = 'sch_get_scheduler_status_list';
export const SCH_GET_SCHEDULER_TASK = 'sch_get_scheduler_task';
export const SCH_DELETE_SCHEDULER_TASK = 'sch_delete_scheduler_task';
export const SCH_CREATE_SCHEDULER_TASK = 'sch_create_scheduler_task';
export const SCH_UPDATE_SCHEDULER_TASK = 'sch_update_scheduler_task';
export const SCH_UPDATING_CONTENT = 'sch_updating_content';
export const SCH_FETCH_START = 'sch_fetch_start';
export const SCH_FETCH_SUCCESS = 'sch_fetch_success';
export const SCH_FETCH_ERROR = 'sch_fetch_error';
export const SCH_RUN_SCHEDULER_TASK = 'sch_run_scheduler_task';
export const SCH_STOP_SCHEDULER_TASK = 'sch_stop_scheduler_task';

export const STOCK_SETTINGS_GET_STOCK_LIST = 'stock_settings_get_stock_list';
export const STOCK_SETTINGS_GET_STOCK_FILTERS =
  'stock_settings_get_stock_filters';

export const STOCK_SETTINGS_GET_STOCK_INFORMATIONS =
  'stock_settings_get_stock_informations';

export const STOCK_SETTINGS_SET_SELECTED_STOCK =
  'stock_settings_set_selected_stock';

export const STOCK_SETTINGS_UPDATE_STOCK_INFORMATIONS =
  'stock_settings_update_stock_informations';

export const GET_STOCK_COMMON_STOCK_LIST = 'get_stock_common_stock_list';
export const SET_STOCK_COMMON_CURRENT_STOCK_CODE =
  'set_stock_common_current_stock_code';

export const CHECK_STOCK_COMMON_STOCK_VALIDITY =
  'check_stock_common_stock_validity';

export const GET_STOCK_COMMON_FILTERS = 'get_stock_common_filters';

export const GET_SA_DASHBORAD_DATA = 'get_sa_dashborad_data';
export const SET_SA_NUMBER_OF_QUARTER = 'set_sa_number_of_quarter';
export const SET_SA_HIDE_TOP_BOX = 'set_sa_hide_top_box';
export const SET_SA_SHOW_AVG = 'set_sa_show_avg';
export const SET_SA_EM = 'set_sa_em';
export const GET_SA_PRICE_LIST = 'get_sa_price_list';
export const GET_SA_STOCK_COMPARISON_DATA = 'get_sa_stock_comparison_data';
export const GET_SA_MODAL_CHART_DATA = 'get_sa_modal_chart_data';

export const ADMIN_FINANCIAL_APP_GET_FIN_DATA =
  'admin_financial_app_get_fin_data';
export const ADMIN_FINANCIAL_APP_UPDATE_FIN_DATA =
  'admin_financial_app_update_fin_data';

export const ADMIN_FINANCIAL_APP_REORDER_FIN_ROWS =
  'admin_financial_app_reorder_fin_rows';

export const ADMIN_FINANCIAL_APP_UPDATE_FIN_ROW =
  'admin_financial_app_update_fin_row';

export const GET_STP_SUMMARY_DATA = 'get_stp_summary_data';
