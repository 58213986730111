import React from 'react';
import { RoutePermittedRole } from '@veo/constants/AppEnums';

const Ozet = React.lazy(() => import('./Ozet'));
const StockImage = React.lazy(() => import('./StockImage'));
const StockComparison = React.lazy(() => import('./StockComparison'));
const HedefFiyat = React.lazy(() => import('./HedefFiyat'));
const TakasDegisim = React.lazy(() => import('./TakasAnalizi/TakasDegisim'));
const Duello = React.lazy(() => import('./Duello'));
export const hissePagesConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: '/hisse/analiz/',
    element: <Ozet />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/hisse/analiz/:stockCode',
    element: <Ozet />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/hisse/stockImage/:stockCode',
    element: <StockImage />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/performans/:reportName',
    element: <StockComparison />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/hisse/hedef-fiyat-analizi/:stockCode',
    element: <HedefFiyat />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/yabanci-takas/degisim-analizi/:days',
    element: <TakasDegisim />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/duello/:stockCode',
    element: <Duello />,
  },
];
