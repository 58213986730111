import {
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  GET_STOCK_COMMON_STOCK_LIST,
  SET_STOCK_COMMON_CURRENT_STOCK_CODE,
  CHECK_STOCK_COMMON_STOCK_VALIDITY,
  GET_STOCK_COMMON_FILTERS,
} from 'toolkit/constants/ActionTypes';

import jwtAxios from '@veo/services/auth/jwt-auth';

import { appIntl } from '@veo/helpers/Common';

export const onGetStockCommonStokList = (searchQuery) => {
  const { messages } = appIntl();
  let params = { searchQuery, expand: 'latestPrice' };
  return (dispatch) => {
    dispatch({ type: FETCH_START });

    jwtAxios
      .get('/stock-common/get-stock-list', { params })
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_STOCK_COMMON_STOCK_LIST,
            payload: data.data.result,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error?.message || 'Hata' });
      });
  };
};
export const onSetCommonCurrentStock = (stock_code) => {
  return (dispatch) =>
    dispatch({
      type: SET_STOCK_COMMON_CURRENT_STOCK_CODE,
      payload: stock_code,
    });
};

export const onCheckStockCommonStockValidity = (stockCode) => {
  const { messages } = appIntl();
  let params = { stockCode, expand: 'stockPublicFloat,hasEm' };
  return (dispatch) => {
    dispatch({ type: FETCH_START });

    jwtAxios
      .get('/stock-common/check-stock', { params })
      .then((data) => {
        if (data.status === 200) {
          console.log(data.data.result);
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: CHECK_STOCK_COMMON_STOCK_VALIDITY,
            payload: data.data.result,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error?.message || 'Hata' });
      });
  };
};

export const onGetCommonFilters = () => {
  const { messages } = appIntl();

  return (dispatch) => {
    dispatch({ type: FETCH_START });

    jwtAxios
      .get('/stock-common/get-common-filters')
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_STOCK_COMMON_FILTERS,
            payload: data.data.result,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error?.message || 'Hata' });
      });
  };
};
